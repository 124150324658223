<template>
  <div id="form">
    <!-- Form -->
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left"
    >
      <!-- 责编 -->
      <el-form-item
        label="责编"
        prop="author"
      >
        <el-input v-model="query.author" />
      </el-form-item>
      <!-- 来源 -->
      <el-form-item
        label="来源"
        prop="source"
      >
        <el-input v-model="query.source" />
      </el-form-item>
      <!-- 标题 -->
      <el-form-item
        label="标题"
        prop="title"
      >
        <el-input v-model="query.title" />
      </el-form-item>
      <!-- 生效时间 -->
      <el-form-item
        label="生效时间"
        prop="start_at"
      >
        <template>
          <div class="effect-time-colum">
            <el-date-picker
              v-model="query.start_at"
              type="datetime"
              placeholder="选择开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="left"
            >
            </el-date-picker>
          </div>
        </template>
      </el-form-item>
      <!-- 排序 -->
      <el-form-item
        label="排序"
        prop="sort"
      >
        <el-input
          max="100"
          v-model="query.sort"
        />
      </el-form-item>
      <!-- 内文 -->
      <el-form-item
        label="内文"
        prop="content"
      >
        <tinymce-editor
          v-model="query.content"
          type="news"
          :id="query.id"
        />
      </el-form-item>

      <!-- 标题小图 -->
      <el-form-item
        label="标题小图"
        prop="upload"
        ref="uploadValidate"
      >
        <template>
          <div class="upload-colum">
            <el-upload
              class="upload-demo"
              ref="uploadTrickPhoto"
              action=""
              :on-remove="(file, fileList)=>handleRemove(file, fileList,1)"
              :on-change="(file)=>handleUploadPhoto(file,1)"
              accept="image/jpg,image/png,image/jpeg"
              :file-list="fileList"
              list-type="picture"
              :disabled="uploadBtnDisable"
              :auto-upload="false"
              :multiple="false"
              :limit="1"
            >
              <el-button
                size="medium"
                type="primary"
                :disabled="uploadBtnDisable"
              >
                <i class="el-icon-upload el-icon--right"></i> 上传图片
              </el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                ＊限制上傳 1 張照片，支持 jpg, png, jpeg 格式，尺寸135*110
              </div>
              <div
                slot="file"
                slot-scope="{file}"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                >
                <a class="el-upload-list__item-name">
                  {{file.name}}
                </a>
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <i
                  class="el-icon-close"
                  @click="(file, fileList)=>handleRemove(file, fileList,1)"
                ></i>
              </div>
            </el-upload>
          </div>
        </template>
      </el-form-item>

      <!-- 标题大图 -->
      <el-form-item
        label="标题大图"
        prop="upload_bg"
        ref="uploadValidate2"
      >
        <template>
          <div class="upload-colum">
            <el-upload
              class="upload-demo"
              ref="uploadTrickPhoto2"
              action=""
              :on-remove="(file, fileList)=>handleRemove(file, fileList,2)"
              :on-change="(file)=>handleUploadPhoto(file,2)"
              accept="image/jpg,image/png,image/jpeg"
              :file-list="fileList2"
              list-type="picture"
              :disabled="uploadBtnDisable2"
              :auto-upload="false"
              :multiple="false"
              :limit="1"
            >
              <el-button
                size="medium"
                type="primary"
                :disabled="uploadBtnDisable2"
              >
                <i class="el-icon-upload el-icon--right"></i> 上传图片
              </el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                ＊限制上傳 1 張照片，支持 jpg, png, jpeg 格式，尺寸360*190
              </div>
              <div
                slot="file"
                slot-scope="{file}"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                >
                <a class="el-upload-list__item-name">
                  {{file.name}}
                </a>
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <i
                  class="el-icon-close"
                  @click="(file, fileList)=>handleRemove(file, fileList,2)"
                ></i>
              </div>
            </el-upload>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <!-- Footer -->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <!-- 取消 -->
      <el-button @click="handleCancel">
        取消
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        @click="handleSubmit"
      >
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { getTrickDetail, postEditTrick } from '@/api/admin/trick'
import TinymceEditor from '@/components/admin/tinymce-editor.vue'
import { adminLogout } from '@/utils/admin'

export default {
  components: {
    TinymceEditor
  },
  data () {
    return {
      formName: 'editForm',
      dateRange: '',
      fileList: [],
      fileList2: [],
      uploadBtnDisable: false,
      uploadBtnDisable2: false,
      query: {
        id: '',
        title: '',
        sort: '',
        start_at: '',
        end_at: '',
        tag: '',
        content: '',
        author: '',
        source: '',
        is_visible: 1,
        upload: null,
        upload_bg: null
      },
      queryRule: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '请输入排序數字',
            trigger: 'blur'
          }
        ],
        start_at: [
          {
            required: true,
            message: '请选择生效时间',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入内文',
            trigger: 'blur'
          }
        ],
        source: [
          {
            required: true,
            message: '请输入新闻来源',
            trigger: 'blur'
          }
        ],
        upload: [
          {
            required: true,
            validator: this.validateUpload,
            trigger: 'blur'
          }
        ],
        upload_bg: [
          {
            required: true,
            validator: this.validateUpload2,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {},
  methods: {
    getUploadInstance(id) {
      if (id === 1) {
        return this.$refs.uploadTrickPhoto
      } else {
        return this.$refs.uploadTrickPhoto2
      }
    },
    getUploadValidate(id) {
      if (id === 1) {
        return this.$refs.uploadValidate
      } else {
        return this.$refs.uploadValidate2
      }
    },
    getFileListInstance(id) {
      if (id === 1) {
        return this.fileList
      } else {
        return this.fileList2
      }
    },
    setUploadBtnDisableInstance(id, isVisible) {
      if (id == 1) {
        this.uploadBtnDisable = isVisible
      } else {
        this.uploadBtnDisable2 = isVisible
      }
    },
    init () {
      getTrickDetail(this.query.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.query = res.data
            window.tinymce.editors[0].setContent(this.query.content)
            this.setUploadList()
            break
          default:
            adminLogout()
            this.$message.error(res.message)
        }
      }).catch(err => {
        adminLogout()
        this.$message.error(err)
      })
    },
    setUploadList () {
      {
        const imgUrlSplit = this.query.photo.split('/')
        const imgName = imgUrlSplit[imgUrlSplit.length - 1]
        this.fileList.push({
          name: imgName,
          url: this.$common.resSrc(this.query.photo)
        })
        this.uploadBtnDisable = true
      }

      {
        const imgUrlSplit = this.query.photo_bg.split('/')
        const imgName = imgUrlSplit[imgUrlSplit.length - 1]
        this.fileList2.push({
          name: imgName,
          url: this.$common.resSrc(this.query.photo_bg)
        })
        this.uploadBtnDisable2 = true
      }
    },
    handleCancel () {
      this.$router.go(-1)
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid) { this.editTrick() }
      })
    },
    editTrick () {
      postEditTrick(this.query.id, this.query)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              // this.$emit('handleRefresh')
              this.handleCancel()
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    handleRemove (file, fileList, id) {
      if (id == 1) {
        this.query.upload = null
        if (this.query.photo) {
          this.query.photo = null
        }
      } else if (id == 2) {
        this.query.upload_bg = null
        if (this.query.photo_bg) {
          this.query.photo_bg = null
        }
      }
      this.setUploadBtnDisableInstance(id, false)
      this.getFileListInstance(id).pop()
      this.getUploadInstance(id).clearFiles()
    },
    handleUploadPhoto (file, id) {
      this.varifyPhotoMeasure(file, id).then(obj => {
        if (obj) {
          if (obj.message) {
            this.$message.error(obj.message)
            this.getUploadInstance(id).clearFiles()
          } else {
            if (id == 1) {
              this.query.upload = file.raw
            } else if (id == 2) {
              this.query.upload_bg = file.raw
            }
            this.setUploadBtnDisableInstance(id, true)
            this.getUploadValidate(id).clearValidate()
          }
        } else {
          this.$message.error('发生错误')
        }
      })
    },
    varifyPhotoMeasure (file, id) {
      const filePath = file.url
      var width = 0
      var height = 0
      if (filePath) {
        const image = new Image()
        image.src = window.URL.createObjectURL(file.raw)
        return new Promise(resolve => {
          image.onload = function () {
            width = this.width
            height = this.height
            let resObj = {}
            if (width !== 135 && height !== 110 && id == 1) {
              resObj = { message: '图片尺寸应为：135*110' }
            } else {
              resObj = { file: file }
            }
            resolve(resObj)
          }
        })
      } else {
        return { message: '上传失敗' }
      }
    },
    validateUpload (rule, value, callback) {
      if (!this.query.upload && !this.query.photo) {
        callback(new Error('请上傳标题小图'))
      } else {
        callback()
      }
    },
    validateUpload2 (rule, value, callback) {
      if (!this.query.upload_bg && !this.query.photo_bg) {
        callback(new Error('请上傳标题大图'))
      } else {
        callback()
      }
    }
  },
  created () {
    this.query.id = this.$route.params.id
    this.init()
  },
  watch: {
    dialogData (value) {
      this.query = value
    }
  }
}
</script>

<style lang="scss">

</style>
<style lang="scss" scoped>
#form {
  max-width: calc((100vw - 200px) * 0.8);
  .effect-time-colum {
    text-align: left;
    & span {
      display: inline-block;
      margin: 0 10px;
    }
  }
  .upload-colum {
    text-align: left;
    .el-upload-list--picture
      .el-upload-list__item.is-ready
      .el-upload-list__item-name {
      line-height: 70px;
      margin-top: 0;
    }
    .el-upload-list__item.is-ready .el-upload-list__item-status-label {
      display: block;
    }
    .el-upload-list--picture .el-upload-list__item-thumbnail{
      object-fit: contain !important;
    }
  }
}
.el-input-number {
  width: 100%;
}
</style>
